window.Gauge = require("gaugeJS");

function activateDisciplineGauge(values) {
    var opts = {
        angle: 0.15, // The span of the gauge arc
        lineWidth: 0.44, // The line thickness
        radiusScale: 1, // Relative radius
        pointer: {
        length: 0.6, // // Relative to gauge radius
        strokeWidth: 0.046, // The thickness
        color: '#000000' // Fill color
        },
        limitMax: false,     // If false, max value increases automatically if value > maxValue
        limitMin: false,     // If true, the min value of the gauge will be fixed
        colorStart: '#6FADCF',   // Colors
        colorStop: '#8FC0DA',    // just experiment with them
        strokeColor: '#E0E0E0',  // to see which ones work best for you
        generateGradient: true,
        highDpiSupport: true,     // High resolution support

        staticLabels: {
            font: "10px sans-serif",  // Specifies font
            labels: [0, 25, 50, 75, 100],  // Print labels at these values
            color: "#000000",  // Optional: Label text color
            fractionDigits: 0  // Optional: Numerical precision. 0=round off.
        },

    };

    const timekeys = ["week", "month", "year"];
    // Database saves the values in decimals so we need to convert it to percentages.
    values = values.map(function(val) {return val * 100});
    

    for (let i = 0; i < timekeys.length; i++) {
        var target = document.getElementById('disciplinemeter-' + timekeys[i]); // your canvas element
        var gauge = new Gauge.Gauge(target).setOptions(opts); // create sexy gauge!
        gauge.setTextField(document.getElementById("gauge-value-" + timekeys[i]));

        gauge.maxValue = 100; // set max gauge value
        gauge.setMinValue(0);  // Prefer setter over gauge.minValue = 0
        gauge.animationSpeed = 32; // set animation speed (32 is default value)
        gauge.set(values[i]); // set actual value
    }

}

window.activateDisciplineGauge = activateDisciplineGauge;